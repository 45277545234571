<template>
  <div class="" v-if="category">
    <el-form :model="category" :rules="rules" ref="form">
      <TopBarTitleComponent title="Categorías" :showBackIcon="true">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonDeleteComponent
              class="mr-4"
              @onConfirmDelete="onConfirmDelete"
            />
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>

      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col>
            <div class="card box-shadow">
              <div class="card-body">
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="title">
                      <slot name="label">
                        <label class="font-semi-bold m-0"
                          >Nombre Categoría</label
                        >
                      </slot>
                      <el-input type="text" v-model="category.title"></el-input>
                    </el-form-item>
                    <el-form-item prop="description">
                      <slot name="label">
                        <label class="font-semi-bold m-0"
                          >Descripción de la categoría</label
                        >
                      </slot>
                      <el-input
                        type="textarea"
                        rows="8"
                        resize="none"
                        v-model="category.description"
                      ></el-input>
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="6"></b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import ButtonDeleteComponent from "../../../components/ButtonDelete";
import Validate from "@/assets/validate";
import catalogueService from "../../services/catalogueService";

export default {
  name: "CategoryEditComponent",
  data: () => ({
    category: null,
    apiResponse: {
      message: null
    },
    rules: {
      title: [Validate.rules.required()]
      // description: [Validate.rules.required()]
    }
  }),
  methods: {
    onChangeInput(data) {
      this.category = { ...this.category, ...data };
    },
    async handleGetCategorId(categoryId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await catalogueService
          .getCategoryId(categoryId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response.data.category) {
          this.category = response.data.category;
        }
      } catch (error) {
        return false;
      }
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await catalogueService
              .updateCategoryId(this.category)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async onConfirmDelete() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await catalogueService
          .deleteCategoryId(this.category.id)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response.data.success) {
          this.$router.push({ name: "catalogue.categories" });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    ButtonPrimaryComponent,
    ButtonDeleteComponent
  },
  mounted() {
    const { id: categoryId } = this.$route.params;
    if (categoryId) this.handleGetCategorId(categoryId);
  }
};
</script>

<style></style>
